<template>
  <section class="dtc-main-section">
    <vue-tabs v-model="tabName">
      <v-tab
        v-for="(item, i) in titles"
        :title="item.title"
        :key="item.comps + i"
      >
       <iframe
            :src="item.comps + `.html`"
             frameborder="0"
          ></iframe>
      </v-tab>
    </vue-tabs>
         
  </section>
</template>

<script>
import { store } from "@/store/global.js";
import { VueTabs, VTab } from "vue-nav-tabs";
import "vue-nav-tabs/themes/vue-tabs.css";


export default {
  name: "LocalCourseManageTab",
  data() {
    return {
      showTab1: false,
      show: true,
      tabName: "",
      allUserRight: [],
      userRights: [],
      mainTitle: "「統計報表」",
      titles: [
        {
          title: "在地養成報表-依縣市別",
          comps: "rptByCounty",
          isActive: false,
          functionId: "99901",
        },
        {
          title: "在地養成報表-依籍屬別",
          comps: "rptByCategory",
          isActive: false,
          functionId: "99902",
        },
        {
          title: "招生統計",
          comps: "rptBySelection",
          isActive: false,
          functionId: "99903",
        },
        {
          title: "重點科別",
          comps: "rptByFocal",
          isActive: false,
          functionId: "99904",
        },
      ],
    };
  },
  computed: {},
  components: {
    VueTabs,
    VTab,

  },
  methods: {
    setUserRight() {
      let arr = Boolean(sessionStorage.functionIds)
        ? JSON.parse(sessionStorage.getItem("functionIds"))
        : [];
      this.titles = this.titles.map((s) => {
        s.isActive = arr.find((k) => k == s.functionId) ? true : false;
        return s;
      });
      this.titles = this.titles.filter((s) => s.isActive);
    },
  },
  created() {},
  beforeMount() {},
  mounted() {
    //this.setUserRight();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.dtc-main-section {
  width: fit-content;
  //   height: 100vh;
  //   overflow-x: hidden;
}
iframe {
  width: 100vw;
  height: clamp(60vh, 90vh, 100vh);
}
</style>
